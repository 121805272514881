import React from 'react'
import { Link } from 'gatsby'

import NavDropdown from 'react-bootstrap/NavDropdown'
import Nav from 'react-bootstrap/Nav'

export default class Navi extends React.Component {
  render() {
    const { siteTitle, ...rest } = this.props
    return (
      <Nav className="container" {...rest}>
        <NavDropdown title={`Why ${siteTitle}?`}>
          <NavDropdown.Item as={Link} to="/why/overview/">
            Overview
          </NavDropdown.Item>
          <NavDropdown.Item as={Link} to="/why/the-science/">
            The Science
          </NavDropdown.Item>
          <NavDropdown.Item as={Link} to="/why/testimonials/">
            Testimonials
          </NavDropdown.Item>
          <NavDropdown.Item as={Link} to="/why/faq/">
            FAQ
          </NavDropdown.Item>
        </NavDropdown>
        <NavDropdown title="Free Resources">
          <NavDropdown.Item as={Link} to="/resources/levels/">
            Levels
          </NavDropdown.Item>
          <NavDropdown.Item as={Link} to="/resources/videos/">
            Videos
          </NavDropdown.Item>
          <NavDropdown.Item as={Link} to="/resources/pdfs/">
            PDFs
          </NavDropdown.Item>
          <NavDropdown.Item as={Link} to="/resources/games/">
            Games
          </NavDropdown.Item>
        </NavDropdown>
        <Nav.Link as={Link} to="/shop/">
          Shop
        </Nav.Link>
        <Nav.Link as={Link} to="/educators/">
          Educators
        </Nav.Link>
        <Nav.Link as={Link} to="/about-us/">
          About Us
        </Nav.Link>
        <Nav.Link as={Link} to="/contact/">
          Contact
        </Nav.Link>
      </Nav>
    )
  }
}
